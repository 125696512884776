





































































































@font-face {
  font-family: 'Handletter';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/naverfont_05@1.0/Handletter.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TAEBAEKmilkyway';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2310@1.0/TAEBAEKmilkyway.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.penFont {
  font-family: TAEBAEKmilkyway;
}

.penFont * {
  font-family: TAEBAEKmilkyway;
}

.video {
  margin-top: 0px;
  width: 100%;
  height: 400px;
  z-index: -1;
}

@media (max-width: 767px) {
  .contentBox {
    position: absolute;
    top: 50px !important;
    padding: 0px 40px 0px 40px !important;
    width: 100vw;
    font-size: 13px !important;

  }

  // .map {
  //   margin-top:150px;
  //   width: 100% !important;
  //   height: 1000px;
  //   z-index: 99999;
  // }
  .map {
    width: 100vw;
    height: calc(100vh - 70px);
    z-index: 99999;
  }

  .video {
    height: 100vh;
  }

  video {
    object-fit: contain !important;
  }
}



.blur1px {
  filter: blur(0.5px);
  // text-shadow: 0 0 0px #fff;
}

.contentBox {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  max-width: 750px;
  max-height: 750px;
  /*border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);*/
  color: #fff;
  z-index: 99;
  font-size: 18px;
}

/*
// .block-map
*/
.block-map {
  margin-bottom: 10px;
}

.block-map__body {
  height: 100vh;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    z-index: 1;
    background: #fff;
    pointer-events: none;
  }

  &::after {
    position: absolute;
    content: '';
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    z-index: 1;
    background-image: #fff;
    ;
    pointer-events: none;
  }

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
  }
}


@media (max-width: breakpoint(lg-end)) {
  .block-map__body {
    height: 500px;
  }
}

@media (max-width: breakpoint(md-end)) {
  .block-map__body {
    height: 440px;
  }
}

@media (max-width: breakpoint(sm-end)) {
  .block-map__body {
    height: 380px;
  }
}

@media (max-width: breakpoint(xs-end)) {
  .block-map__body {
    height: 300px;
  }
}
