

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.title {
  font-weight: bold;
}

.drag-box {

  padding: 10px;
  /* position: absolute; */
  top: 100px;
  left: 100px;
  width: 100%;
  min-height: 100px;
  /* max-height: 100%; */
  height: calc(100vh - 21vh);
  background: #fff;
  border-radius: 5px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, .15); */
  z-index: 0;
}

.hotname-box {
  padding: 10px;
  /* position: absolute; */
  top: 8px;
  left: 475px;
  min-height: 45px;
  max-height: 750px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  z-index: 99;
}

.baseinfo-box {
  padding: 10px;
  position: static;
  top: 8px;
  left: 475px;

  min-height: 45px;
  max-height: 750px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  z-index: 99;
}

.detail-box {
  position: relative;
  min-height: 633px !important;
  padding: 10px;
  top: 8px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  // z-index: 9999;
}

.image-map-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
}

.part-detail-image {
  max-width: 100%;
  transition: transform 0.2s ease-in-out;
}

.map-opt {
  position: absolute;
  top: 0px;
  right: 10px;
  width: 26px;
}

.map-opt div {
  display: inline-block;
  font-size: 10px;
  width: 26px;
  height: 26px;
  line-height: 26px;
  color: rgba(0, 155, 103, 1);
  text-align: center;
  vertical-align: middle;
  -webkit-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .4);
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .4);
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
  margin-bottom: 5px !important;
}

.area {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  border-radius: 2px;
}

.area:hover {
  background-color: rgba(0, 110, 255, 0.3);
}

.areamove {
  background-color: rgba(200, 255, 0, 0.5);
}

.bg-red {
  background-color: rgba(255, 0, 85, 0.2) !important;
}

.select-red {
  background: rgba(255, 0, 50, 0.3) !important;
}

.fixedSub {
  /* flex: 0 0 41.666667%;
  position: fixed !important;
  top: 50px;
  left: 16.7vw;
  left: calc(50% - 400px);
  transform: translateX(-50%);
  min-width: 32.533vw;
  overflow: hidden;
  padding-right: 15px;
  padding-left: 15px;
  transition: top 0.3s ease-in-out;
  height: calc(100vh - 60px) !important; */
  flex: 0 0 41.666667%;
  position: fixed !important;
  top: 100px;
  left: 2.2vw;
  max-width: calc(32.166667%);
  padding-right: 15px;
  padding-left: 15px;
  /* transition: transform 0.3s ease-in-out;*/
  transition: top 0.3s ease-in-out;
}

.fixedBottom {
  top: 0px;
  bottom: 300px !important;
  transition: top 0.3s ease-in-out;

}
