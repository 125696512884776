@import url('//fonts.googleapis.com/earlyaccess/notosanskr.css');


@font-face {
  font-family: 'TAEBAEKmilkyway';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2310@1.0/TAEBAEKmilkyway.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


/* fonts.googleapis.com/earlyaccess/notosanskr.css */

.notosanskr * {
  font-family: 'Noto Sans KR', !important;
}

::-webkit-scrollbar {
  width: 0;
  /* 수직 스크롤바 너비 */
}

/* 수평 스크롤바 */
::-webkit-scrollbar {
  height: 0;
  /* 수평 스크롤바 높이 */
}

/* 수직, 수평 스크롤바 모두 숨김 */
::-webkit-scrollbar {
  display: none;
}

/* div자체 가운데정열 */
.divCenter {
  margin: 0 auto;
}

.right {
  text-align: right !important;
}

body {
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.5px;
  font-family: 'Noto Sans KR' !important;
  font-weight: 400 !important;
  font-size: 13px;
  min-height: 99vh;
}

.noselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noselect * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed !important;
}


.backgray {
  background-color: #ccc !important;
}

.bacllight {
  background-color: #efefef !important;
}


.colorwhite {
  color: #fff !important;
}

.colorwhite input {
  color: #fff !important;
}


.coloryelow {
  color: rgba(255, 255, 0) !important;
}

.colorgray {
  color: #777 !important;
}

.colordeepgray {
  color: #333 !important;
}

.colordark {
  color: rgb(10, 10, 15) !important;
}

.colorgraylight {
  color: #ccc !important;
}

.colorlight {
  color: #eee !important;
}

.colorlightddd {
  color: #ddd !important;
}

.colorblue {
  color: rgb(105, 192, 252);
}


.colororange {
  color: #ff5533 !important;
}

.colorred {
  color: #ff0022 !important;
}

.colorreddeep {
  color: #dd0022 !important;
}



.link_logo {
  display: block;
  /* max-width: 200px; */
  font-size: 18px;
  font-weight: 600;
  color: #333;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-height: 22px;
}

.ellipline {
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.ellip {
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap !important;
}



.searchBox {
  padding: 0px 5px 0px 5px;
  margin-top: 60px;
  border-radius: 5px;
  background-color: #eee;
  line-height: 40px;
}

.searchBox {
  /* border-bottom: #333 2px solid; */
  /* border: none; */
  /* width: 100%; */
  /* font-size: 15px; */
  /* padding: 8px 5px 0px 5px; */
}

.searchBox {
  position: relative;
}

.searchBoxmd input {
  border: none;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  padding: 5px;
  line-height: 30px;
  background-color: #eee;
}

.searchBoxmd button {
  top: 0px;
  right: 0px;
}

.searchButton {
  position: absolute;
  top: 0;
  right: 10px;
  width: 30px;
  /* 돋보기 아이콘의 너비 */
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #666;
}

.topSmallmenu span {
  cursor: pointer;
}

.topSmallmenu span:not(:last-child)::after {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 12px;
  color: #969696;
  content: "·";
  font-weight: bold;
}

.topbar {
  border-bottom: 1px solid #dededf;
  line-height: 40px;
}

.cyz {
  background: url('/static/img/cyz.gif');
  background-size: cover;
  width: 100px;
  height: 100px;
  position: absolute;
  mix-blend-mode: multiply;
  opacity: 1;
}


.nodata {
  background: url('/static/img/nodata.png');
  background-size: cover;
  width: 98px;
  height: 87px;
  mix-blend-mode: multiply;
  opacity: 0.2;
}

.bodybanner {
  position: relative;
  margin-top: 30px;
  width: 100%;
  height: 50px;
  background-color: #000;
  margin-bottom: 30px;
  background: url('/static/img/banner-small.webp');

}

.bodybanner::after {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #aaa;
  content: 'BODYBANNER W 100% X H 50PX';
}


.footitems * {
  line-height: 25px !important;
}

.container {
  margin-top: 0px !important;
}

.lh25 {
  height: 20px;
  line-height: 25px;
}

.lh30 {
  height: 30px;
}

.lh30 {
  line-height: 30px;
}

.lh40 {
  line-height: 40px;
}

.center {
  text-align: center;
}

.centerleftmenuRow {
  overflow-x: auto !important;
  overflow: hidden;
  line-height: 50px;
  border-bottom: 2px #ff5500 solid;
}

.productInfoTitle {
  border-bottom: 2px #333 solid !important;
  line-height: 40px;
}

.homemenu {
  border-bottom: 2px #efefef solid !important;
  line-height: 10px;
}

.homemenu span {
  padding: 0px 5px 2px 5px;
}

.homemenu span:hover {
  padding: 0px 5px 2px 5px;
  background-color: #efefef;
  color: #000;
  border-radius: 5px;
}

.homemenu span:first-child {
  /* margin-left: 30px !important; */
}

.homemenu span:not(:last-child) {
  font-size: 15px;
  margin-right: 10px;
}

.homemenumobile {
  border-top: 2px #333 solid !important;
  border-bottom: 2px #333 solid !important;
  line-height: 40px;
}

.homemenumobile span {
  margin-left: 30px !important;
  line-height: 40px;
}

.mobilemenuprofile {
  border-radius: 3px;
  width: 100%;
  height: 100px;
  background-color: #e1e1e5;
}

.mobilemenuprofile .iconDiv {
  width: 50px;
  height: 50px;
}

.bigImageSize {
  width: 300px;
  height: 300px;
}

.bigImage {
  width: 300px;
  height: 300px;
}

.productSmallImage {
  border: 2px #EBEEF5 solid;
  cursor: pointer;
  margin-top: 5px;
  margin-right: 5px;
  width: 30px;
  height: 30px;
}

.blur03 {
  filter: blur(0.799px)
}

.icon {
  position: absolute;
  top: 25px;
  left: 10px;

  border-radius: 50px;
  background-color: rgb(144, 173, 255);
  color: #fff;
  font-size: 28px;
  overflow: hidden;
  padding: 2px 10px !important;
}

.productView {
  cursor: pointer;
  margin: 5px;
  min-width: 144px;
  padding-bottom: 5px;
  /* height: 260px; */
}

.productView:hover .viewDiv {
  padding: 10px;
  position: absolute;
  z-index: 999;
  min-width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 1));
  color: #fff;
  border-radius: 5px;
  text-align: center;
  display: table;
  animation: fadeIn 0.3s ease forwards;
}

.button {
  display: none;
  opacity: 1;
}

.productView:hover .viewDiv:hover .button {
  display: table-cell;
  vertical-align: middle;
  animation: fadeIn 0.9s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.detailPage .buttonsbox {
  padding: 5px;
  text-align: right;
}

.displaynone {
  display: none;
}

.quantitiybox {
  position: relative;
}

.quantitybox input[type='number'] {
  margin-left: 20px;
  text-align: center;
  width: 100px;
  outline: none;
  border-radius: 15px;
  border: 1px #969696 solid !important;
  font-size: 18px !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantitybox .minus,
.quantitybox .plus {
  color: #969696;
  font-size: 20px;
  top: 0px;
  cursor: pointer;
}

.quantitybox .minus {
  position: absolute;
  left: 30px;
}

.quantitybox .plus {
  position: absolute;
  right: 10px;
}

.productTitle {
  height: 37px;
  font-size: 13px;
  width: 120px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.productPrice {
  font-size: 16px;
  font-weight: bold;
}

.productPrice::after {
  content: '원'
}


.productImage {
  /* background: url('https://api-new.008parts.co.kr/upload/product/20240221_141739.jpg') no-repeat center center / cover; */
  position: relative;
  border-radius: 10px;
  min-height: calc(100vh * 0.15);
}

.productImage:hover {
  background-color: rgba(0, 0, 0, 1);

}

.productImageItemCart {
  transition: opacity 0.3s ease;
  /* opacity에만 transition 적용 */
  border-radius: 10px;
  /* min-width: calc(100vw * 0.12); */
  height: 70px;
  width: 70px;
}


.productImageItem {
  transition: opacity 0.3s ease;
  /* opacity에만 transition 적용 */
  border-radius: 8px;
  /* min-width: calc(100vw * 0.12); */
  min-height: calc(100vh * 0.15);
}

.productImageItem:hover {
  opacity: 0.5;
  animation: zoomInOut 0.9s ease;
  /* hover 상태에서 애니메이션 적용 */
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1.5);
    opacity: 0;

  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* 흑백 */
.grayscale {
  filter: grayscale(100%) !important;
}

.drageinfo {
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translateX(-50%);
  z-index: 2;
  width: 300px;
  line-height: 40px;
  border-radius: 5px;
  background-color: rgba(0, 0, 5, 0.5);
  font-size: 15px;
  color: #fff;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.drageinfo div {
  text-align: center;
}

.parent-div {
  position: relative;
}

.parent-div:hover .drageinfo {
  opacity: 1;
  /* transition-delay: 0.3; */
  animation: fadeOut 1s forwards;

}

.drageinfo,
.drageinfo * {
  pointer-events: none;
}

/* 삭제선 */
.delline,
.fontline-through {
  text-decoration: line-through;
}

.font10 {
  font-size: 10px;
}

.font12 {
  font-size: 12px;
}

.font13 {
  font-size: 13px;
}

.font13 * {
  font-size: 13px;
}

.font15 {
  font-size: 15px;
}

.font18 {
  font-size: 18px;
}

.font20 {
  font-size: 20px;
}

.font30 {
  font-size: 30px;
}

.formTitle {
  font-size: 24px;
  font-weight: bold;
}


.inputbox {
  width: 100%;
  border: 1px #fff solid !important;

  &&:hover {
    border-radius: 5px;
    border: 1px #d5d5d5 solid !important;
  }

  &&::placeholder {
    text-align: center;
    color: #d5d5d5 !important;
  }
}

.input-container {
  position: relative;
}

.formtextbox {
  font-size: 18px;
  margin-top: 30px;
  width: 100%;
  border: none;
  border-bottom: 2px #343435 solid;
}

.formtextboxreadonly {
  font-size: 18px;
  margin-top: 30px;
  width: 100%;
  background-color: #eee;
  border: none;
  border-bottom: 2px #343435 solid;
}

.formtextboxSecret {
  padding-left: 115px;
  font-size: 18px;
  margin-top: 30px;
  width: 100%;
  border: none;
  border-bottom: 2px #343435 solid;
}

.placeholder {
  position: absolute;
  top: 30px;
  /* 초기 위치 */
  left: 0px;
  /* 초기 위치 */
  color: #999;
  font-size: 16px;
  transition: top 0.3s, font-size 0.3s;
  pointer-events: none;
  /* 입력창 클릭 이벤트를 가리기 위해 */
}

.placeholder.active {
  top: 10px;
  left: 0px;
  font-size: 12px;
}

.w100p {
  width: 100%;
}

.hand,
.hand span,
.hand * {
  cursor: pointer !important;
}

.move {
  cursor: move;
}

.btn-mini {
  height: 25px;
  line-height: 25px;
  padding: 0 0.5rem;
  font-weight: 500;
  font-size: 12px;
}

.btn-red {
  border: 1px #ff0055 solid !important;
  background-color: #ff0055 !important;
  color: #fff;
  /* padding-bottom: 5px !important; */
}

.btn-dark {
  border: 1px #333 solid !important;
  background-color: #333 !important;
  color: #fff;
  /* padding-bottom: 5px !important; */
}

.btn-gray {
  background-color: rgb(219, 219, 224);
  border: 1px rgb(219, 219, 224) solid;
}

.menubar {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 5px 0px 5px 0px;
  border-top: 1px #EBEEF5 solid;
  border-bottom: 1px #EBEEF5 solid;
}

.menubadge {
  text-align: center;
  border-radius: 3px;
  line-height: 20px;
  padding: 0px !important;
  margin-bottom: 5px;
}

.menuitem {
  padding: 5px;
  cursor: pointer;
}

.menuitem:hover {
  background-color: #efeff5 !important;
}

.borderbox {
  border: 1px solid rgb(240, 240, 245);
  border-radius: 5px;
}

.borderbottom3px-orange {
  border-bottom: 3px #ff5500 solid;
}

.borderbottom1px {
  border-bottom: 1px #efefef solid;
}

/* .newsTitles div:not(:last-child)::after { */

.borderbottom1px div:last-child {
  border: none !important;
}

.bordertop1px {
  border-top: 1px #dededf solid;
}

.badge {
  padding-top: 3px;
}

.badge-small {
  padding: 2px 3px 2px 2px !important;
  font-size: 10px !important;
}

.bigbadge-small {
  /* padding: 2px 3px 2px 2px !important; */
  font-size: 11px !important;
}

.badge-dark {
  border-radius: 0px;
  border: 2px #000 solid;
  padding: 0px 2px 0px 2px;
  background-color: rgb(0, 0, 0);
  color: #fff;
  font-size: 10px;
}

.badge-dark:hover {
  border-radius: 0px;
  border: 2px #000 solid;
  padding: 0px 2px 0px 2px;
  background-color: rgb(0, 0, 0);
  color: #fff;
  font-size: 10px;
}


.bigbadge-border {
  padding: 5px;
  border: 2px #dededf solid;
}


.bigbadge-dark {
  border-radius: 0px;
  border: 2px #000 solid;
  padding: 0px 0px 2px 5px;
  background-color: rgb(0, 0, 0);
  color: #fff;
}

.bigbadge-dark .empty {
  margin-left: 3px;
  padding: 0px 5px 2px 5px;
  background-color: rgb(255, 255, 255);
  color: #000;
}


.bigbadge-light {
  padding: 5px 7px 5px 5px;
  background-color: rgb(245, 245, 247);
  border-radius: 5px;
}

.bigbadge-yellow {
  padding: 5px 7px 5px 5px;
  background-color: #feffdd;
  border-radius: 5px;
}

.bigbadge-hover:hover {
  cursor: pointer;
  background-color: #006eff;
  color: #fff;
}

.bigbadge-blue {
  /*상 우 하 좌*/
  padding: 5px 7px 3px 5px;
  background-color: rgb(105, 192, 252);
  border-radius: 5px;
  color: #fff;
}

.bigbadge-red {
  /*상 우 하 좌*/
  padding: 5px 7px 5px 5px;
  background-color: rgba(255, 0, 34, 0.5);
  border-radius: 5px;
  color: #fff;
}

.badge-orange {
  padding: 5px 7px 5px 5px;
  color: #fff;
  border-radius: 5px;
  background-color: #ff5500;
}

.badge-yeondoo {
  color: #000;
  background-color: rgb(218, 247, 166);
}

.badge-green {
  color: #fff;
  background-color: rgb(84, 179, 21);
}


.badge-red {
  padding-top: 3px;
  color: #fff;
  background-color: rgb(255, 0, 34, 0.5);
}

.badge-red50 {
  padding-top: 3px;
  color: #fff;
  background-color: rgba(255, 17, 85, 0.5);
}

.badge-blue {
  padding-top: 3px;
  color: #fff;
  background-color: rgb(105, 192, 252);
}
.badge-bluedeep {
  padding-top: 3px;
  color: #fff;
  background-color: rgb(61, 125, 221);
}
.badge-black {
  color: #fff;
  background-color: rgb(0, 0, 0);
}

.badge-black50 {
  color: #fff;
  background-color: rgb(0, 0, 0, 0.5);
}

.badge-darklight {
  background-color: rgb(240, 240, 245);

}


.cutCheck .el-checkbox__inner {
  width:20px;
  height:20px;
  border:2px solid rgb(0,0,3,0.1);
}
.cutCheck .is-checked{
  border-radius: 3px;
  border-color:#409EFF;background-color:#409EFF
}
.cutCheck .el-checkbox__input.is-checked .el-checkbox__inner::after {
  padding-left:4px;
  padding-top:4px;
}

.fixedTooltip-info {
  position: absolute;
  color: #dededf;
  font-size: 13px;
  text-decoration: underline;
}

.fixedTooltip-link {
  position: absolute;
  color: rgb(0, 92, 212);
  font-size: 13px;
  text-decoration: underline;
}

.fixedTooltip-link-f {
  color: rgb(0, 92, 212);
  font-size: 13px;
  text-decoration: underline;
}

.fixedTooltip-danger {
  position: absolute;
  color: #ff0033;
  font-size: 13px;
  text-decoration: underline;
}



.badge0 {
  display: inline-block;
  padding: 2px 5px 4px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.bold {
  font-weight: bold !important;
}

.noticecategory div {
  padding-left: 10px;
  line-height: 30px;
  margin-top: 20px;
}

.noticecategory div:hover {
  cursor: pointer;
  background-color: #efefef;
  border-radius: 5px;
}

.newsTitles {
  font-size: 18px;
}


.newsTitles label {
  margin: 0px;
}

.newsTitles div:not(:first-child)::after {
  content: '';
  display: block;
}

.newsTitles div:not(:last-child)::after {
  content: '';
  display: block;
  /* border-bottom: 1px #EBEEF5 solid !important; */
  /* margin-bottom: 20px; */
  padding-bottom: 20px;
}

.warningbox {
  background-color: rgb(240, 240, 245, 1);
  color: rgb(255, 0, 85, 1);
  padding: 10px;
}


.table-params {
  border-top: 1px #dee2e6 solid;
  border-left: 1px #dee2e6 solid;
}

.table-params .th {
  padding: 5px;
  background-color: #f8f9fa;
  border-right: 1px #dee2e6 solid;
  border-bottom: 1px #dee2e6 solid;
}

.table-params th {
  font-weight: 500;
  padding: 5px;
  background-color: #f8f9fa;
  border-right: 1px #dee2e6 solid;
  border-bottom: 1px #dee2e6 solid;
}

.table-params .td {
  padding: 5px;
  border-right: 1px #dee2e6 solid;
  border-bottom: 1px #dee2e6 solid;
}

.table-params td {
  padding: 5px;
  border-right: 1px #dee2e6 solid;
  border-bottom: 1px #dee2e6 solid;
}

/* 특정 요소의 뒷면을 필터링하여 흐리게 만듬 */
/*모바일버전에서 사용함*/
.lastBlur {
  -webkit-backdrop-filter: saturate(180%) blur(10px) !important;
  backdrop-filter: saturate(180%) blur(10px) !important;
}

.top2pxshadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.bottom2pxshadow {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.25);
}

.gohomemd {
  line-height: 50px;
}

.gohome {
  line-height: 50px;
  display: none;
}


.warning-content {
  font-size: 13px;
  font-weight: normal;
  border-radius: 2px;
  background-color: #f5f5f5;
  padding: 10px;
}

.el-button--small {
  padding-top: 15px;
  padding-bottom: 15px;
}

.el-collapse-item .el-collapse-item__content {
  box-shadow: none !important;
}

.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #EBEEF5 !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: rgb(235, 238, 245) !important;
  border-right: 1px solid #EBEEF5 !important;
  padding: 5px 5px !important;
}

.el-input__inner {
  color: #000 !important;
}


.el-btn-red {
  border: 0px #ff0033 solid;
  background-color: #ff0033 !important;
  color: #fff !important;
}

.el-btn-red:hover {
  border: 0px #dd0022 solid;
  background-color: #dd0022;
  color: #fff !important;
}

.el-btn-red:disabled {
  background-color: #efefef !important;
  border-color: #ccc !important;
  color: #999 !important;
}

.el-btn-orange {
  border: 0px #ff5500 solid;
  background-color: #ff5500 !important;
  color: #fff !important;
}

.el-btn-orange:hover {
  border: 0px #ff3300 solid;
  background-color: #ff3300;
  color: #fff;
}

.el-btn-orange:disabled {
  background-color: #efefef !important;
  border-color: #ccc !important;
  color: #999 !important;
}

.el-btn-light {
  border: 0px #efefef solid;
  background-color: #efefef !important;
  color: #000 !important;
}

.el-btn-light:hover {
  border: 0px #cfcfcf solid;
  background-color: #cfcfcf;
  color: #000;
}

.el-btn-light:disabled {
  background-color: #efefef !important;
  border-color: #ccc !important;
  color: #999 !important;
}

.listSplit span {
  margin-right: 10px;
}

.listSplit span:last-child {
  margin-right: 0;
}


.inline {
  display: flex;
}

/* .inine>div {
  margin-right: 10px !important;
}

.inine>div:last-child {
  margin-right: 0;
} */

.xinfengborder {
  border-radius: 5px;
  border: 5px solid transparent;
  background:
    linear-gradient(white, white) padding-box,
    repeating-linear-gradient(-45deg,
      rgba(255, 0, 0, 0.2) 0,
      rgba(255, 0, 0, 0.2) 12.5%,
      /* 빨강색너비*/
      transparent 0,
      transparent 25%,
      /* 파랑색너비*/
      rgba(0, 0, 255, 0.2) 0,
      rgba(0, 0, 255, 0.2) 37.5%,
      transparent 0,
      transparent 50%) 0/5em 5em;
}




@media (max-width: 767px) {
  .mobile-mt1 {
    margin-top: 10px
  }

  .mobile-mt15 {
    margin-top: 15px
  }

  .mobile-mt2 {
    margin-top: 20px
  }


  .quantitybox input[type='number'] {
    line-height: 40px;
  }

  .productTitle {
    width: 100px;
    color: #ccc;
  }

  .detailPage .buttonsbox {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25) !important;
    border-top: 1px rgb(225, 225, 225) solid;
    width: 100%;
    background-color: rgb(225, 225, 225, 0.5) !important;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    text-align: center;
  }

  .productSmallImage {
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }

  .bigImage {
    width: 330px;
    height: 330px;
    border-radius: 5px;
  }

  .bigImageSize {
    width: 330px;
    height: 330px;
    border-radius: 5px;
  }

  .searchButton {
    top: 29px;
  }

  .gohome {
    /* position: fixed; */
    display: block;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.25);
    background-color: rgba(255, 255, 255, 0.5);
    width: 100vw;
  }
}

.vingroupimg {
  width: 110px;
  height: 110px;
  border: 1px #ddd solid;
  border-radius: 2px;
  cursor: pointer;
}

.vingroupimg:hover {
  border: 1px solid #ff0055;
}

.vingroupimg-title {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
  color: #000;
  background-color: rgba(0, 0, 5, 0.0);
}


.china {
  width:14px;
  height:9.63px;
  background: url('/static/img/china.webp');
  background-size: cover;
}
.korea {
  width:14px;
  height:9.63px;
  background: url('/static/img/korea.webp');
  background-size: cover;
}

.china18 {
  width:18px;
  height:12px;
  background: url('/static/img/china.webp');
  background-size: cover;
}
.korea18 {
  width:18px;
  height:12px;
  background: url('/static/img/korea.webp');
  background-size: cover;
}


.el-dialog__header *{
  color:#ccc !important;
}