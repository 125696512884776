


















































































































































































































































































































































































































body *{
    font-family: 'dotum';
    font-size: 13px;
    letter-spacing: 0.01em;
}
th {
    padding: 2px !important;
    font-weight: bold !important;
}

.sticky-bottom {
    bottom: 0px;
    left: 0px;
    position: fixed;
    z-index: 1000;
}

.border-all-dark-2px {
    border: 2px #000 solid !important;
}

@media print {
    .noprint {
        display: none;
    }

    body * {
        padding-top: 0px;
        transform: scale(100%);
        // font-weight: normal !important;
        /* 크기 조절 (0.5는 50% 크기) */
        transform-origin: top left;
        /* 크기 조절의 기준점 설정 */
        font-size: 14pt !important;
    }

    .printfont14pt {
        font-size: 14pt !important;
    }

    .printcustomer {
        height: calc(100% - 56px) !important;
    }

    th {
        font-weight: bold;
        padding: 5px;
        // background-color: #f7f7f7 !important;
        border-right: 1px solid #000 !important;
        border-bottom: 1px solid #000 !important;
    }

    td {
        height: 30px;
        padding: 0px;
        padding-left: 5px;
        padding-right: 5px;
        border-right: 1px solid #000 !important;
        border-bottom: 1px solid #000 !important;
    }

    table {
        width: 100%;
        border-left: 1px #000 solid !important;
        border-top: 1px #000 solid !important;
    }
}
